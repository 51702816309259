import { MoreVert } from '@mui/icons-material';
import { Box, Divider, MenuItem as MuiMenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  KebabMenuOption,
  KebabMenuOptionColorType,
  MenuPopover,
  RoundButton,
} from '@ysura/common';
import { MouseEvent, useState } from 'react';

type VideoControlsMobileKebabPopoverProps = {
  menuEntries: Array<KebabMenuOption>;
  anchorOrigin?: {
    vertical: 'top' | 'center' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
  transformOrigin?: {
    vertical: 'top' | 'center' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
};

export const VideoControlsMobileKebabPopover = ({
  menuEntries,
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'center',
  },
  transformOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
}: VideoControlsMobileKebabPopoverProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
    setIsPopoverOpen(true);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setIsPopoverOpen(false);
  };

  return (
    <>
      <RoundButton
        size="medium"
        variant="secondary"
        testId="video-controls-mobile-kebab-button"
        onClick={(e: MouseEvent<HTMLElement>) => handleOpenPopover(e)}
      >
        <MoreVert />
      </RoundButton>

      <MenuPopover
        data-testid="video-controls-mobile-kebab-popover"
        open={isPopoverOpen}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={handleClosePopover}
      >
        {menuEntries
          .filter((option) => !option.hidden)
          .map((option, index) => {
            const { entry, icon, onClick, colorType = 'secondary' } = option;
            const isLastOption = index === menuEntries.length - 1;

            return (
              <Box
                key={index}
                data-testid={`video-controls-mobile-kebab-entry-${index}`}
              >
                <MenuItem
                  sx={{ m: 1 }}
                  colorType={colorType}
                  onClick={() => {
                    onClick();
                    handleClosePopover();
                  }}
                >
                  {icon} {entry}
                </MenuItem>
                {!isLastOption && <Divider />}
              </Box>
            );
          })}
      </MenuPopover>
    </>
  );
};

type ContentProps = { colorType: KebabMenuOptionColorType };

const MenuItem = styled(MuiMenuItem, {
  shouldForwardProp: (prop) => prop !== 'colorType',
})<ContentProps>(({ theme, colorType }) => {
  const getColorFromType = (colorType: KebabMenuOptionColorType) => {
    if (colorType === 'inherit') {
      return 'inherit';
    }

    if (colorType === 'error') {
      return theme.palette.error.main;
    }

    return theme.palette.text[colorType];
  };

  return {
    gap: theme.spacing(1),
    color: getColorFromType(colorType),
  };
});
