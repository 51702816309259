import { Box, Grid as SpacingGrid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

import { NotificationBannerList } from '../Notification';

type InteractionDetailPageLayoutProps = {
  header: ReactNode;
  firstColumn: ReactNode | null;
  middleColumn: ReactNode | null;
  lastColumn: ReactNode | null;
};
export const InteractionDetailPageLayout = ({
  header,
  firstColumn,
  middleColumn,
  lastColumn,
}: InteractionDetailPageLayoutProps) => {
  const allColumns = [firstColumn, middleColumn, lastColumn].filter(
    (comp) => comp
  );

  return (
    <SpacingGrid container spacing={{ xs: 2, md: 3 }}>
      {/* Header */}
      <HeaderGrid item xs={12}>
        {header}

        <NotificationBannerList displayLocation="f2f" />
      </HeaderGrid>

      {/* First column */}
      <SpacingGrid item xs={12} md={4}>
        <Column>{allColumns[0]}</Column>
      </SpacingGrid>

      {/* Middle column */}
      <SpacingGrid item xs={12} md={4}>
        <Column>{allColumns[1]}</Column>
      </SpacingGrid>

      {/* Last column  */}
      <SpacingGrid item xs={12} md={4}>
        <Column>{allColumns[2]}</Column>
      </SpacingGrid>
    </SpacingGrid>
  );
};

const HeaderGrid = styled(SpacingGrid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const Column = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));
