import { Box, Button, Divider, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Image } from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import interactLogo from '@/assets/interactLogo.svg';
import { MoreMenuButton } from '@/components/Button';
import { Logo } from '@/components/Logo';
import { SYSTEM } from '@/config';
import { useAuth } from '@/hooks';

export const Login = () => {
  const { isInitialized, login } = useAuth();
  const { t } = useTranslation();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  return (
    <Wrapper>
      <Header>
        <Logo />

        <MoreMenuButton open={open} setOpen={setOpen}>
          <MenuItem
            sx={{ m: 1 }}
            onClick={() => login({ idpHint: 'ysuraHub' })}
          >
            {t('pages.login.signInAsYsura')}
          </MenuItem>
          <Divider />
          <MenuItem sx={{ m: 1 }} onClick={() => login({ idpHint: 'local' })}>
            {t('pages.login.signInAsLocal')}
          </MenuItem>
        </MoreMenuButton>
      </Header>

      <Content>
        {/* CIP-72: Added the visibleByDefault property according to this:
        https://github.com/Aljullu/react-lazy-load-image-component/issues/87#issuecomment-959540934
        This fixes this image not showing up on iOS 15.4.x browsers. */}
        <StyledImage
          visibleByDefault
          src={interactLogo}
          data-testid="dynamic-logo"
        />

        <StyledButton
          color="primary"
          size="large"
          variant="contained"
          disabled={!isInitialized}
          onClick={() => login()}
        >
          {t('pages.login.signIn')}
        </StyledButton>
      </Content>

      <Footer data-testid="version">{SYSTEM.VERSION}</Footer>
    </Wrapper>
  );
};

const Wrapper = styled('main')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
  padding: theme.spacing(3),
}));

const Header = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

const Content = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'center',
  gap: 40,
  maxWidth: '100%',
  width: 400,
});

const StyledButton = styled(Button)({
  maxWidth: '100%',
  textTransform: 'uppercase',
  width: 320,
});

const Footer = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const StyledImage = styled(Image)({
  width: 320,
});
