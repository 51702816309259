import { Box, Divider, MenuItem, Stack, Typography } from '@mui/material';
import { alpha, Theme } from '@mui/material/styles';
import {
  getPersonFullNameWithTitle,
  IconButtonAnimate,
  MenuPopover,
} from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { MyAvatar } from '@/components/Avatar';
import { useAuth, useCurrentUser } from '@/hooks';

type AccountPopoverProps = {
  handleImpersonationClick: VoidFunction;
};

export const AccountPopover = ({
  handleImpersonationClick,
}: AccountPopoverProps) => {
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const { t } = useTranslation();
  const { logout } = useAuth();
  const { currentUser } = useCurrentUser();

  const MENU_OPTIONS = [
    {
      label: t('appbar.accountPopover.menuItems.home'),
      linkTo: '/',
    },
  ];

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleImpersonate = () => {
    handleImpersonationClick();
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      <IconButtonAnimate
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgColor: (theme: Theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
        data-testid="appbar-account-menu-avatar"
        onClick={handleOpen}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover open={Boolean(open)} anchorEl={open} onClose={handleClose}>
        {currentUser && (
          <>
            <Box sx={{ my: 1.5, px: 2.5 }}>
              <Typography noWrap variant="subtitle2">
                {currentUser?.person?.firstName
                  ? getPersonFullNameWithTitle(currentUser?.person)
                  : currentUser?.username}
              </Typography>
              <Typography
                noWrap
                variant="body2"
                sx={{ color: 'text.secondary' }}
              >
                {currentUser?.email}
              </Typography>
            </Box>

            <Divider sx={{ borderStyle: 'dashed' }} />

            <Stack sx={{ p: 1 }}>
              {MENU_OPTIONS.map((option) => (
                <MenuItem
                  key={option.label}
                  to={option.linkTo}
                  component={RouterLink}
                  onClick={handleClose}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Stack>

            <Divider sx={{ borderStyle: 'dashed' }} />

            {currentUser?.permissions?.user.canImpersonate && (
              <>
                <MenuItem sx={{ m: 1 }} onClick={handleImpersonate}>
                  {t('appbar.accountPopover.menuItems.impersonate')}
                </MenuItem>

                <Divider sx={{ borderStyle: 'dashed' }} />
              </>
            )}
          </>
        )}

        <MenuItem sx={{ m: 1 }} onClick={handleLogout}>
          {t('appbar.accountPopover.menuItems.logout')}
        </MenuItem>
      </MenuPopover>
    </>
  );
};
