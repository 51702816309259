import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LabelsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  flexWrap: 'wrap',
  span: {
    whiteSpace: 'pre-line',
    minHeight: '22px',
    lineHeight: '20px',
  },
}));
